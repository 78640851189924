import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    idUser: 0,
    token: '',
}
 
export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLogin: (state, action) => {
            state.idUser = action.payload.idUser
            state.token = action.payload.token
        },
        setLogout: (state, action) => {
            state.idUser = 0
            state.token = ''
        }
    },
    returns: {
        setLogin: (idUser, token) => ({
            type: 'user/setLogin',
            payload: {
                idUser,
                token,
            },
        }),
        setLogout: () => ({
            type: 'user/setLogout',
        }),
    },

    

});


export const { setLogin, setLogout } = user.actions;


export default user.reducer;