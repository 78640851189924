import React from 'react'

export default function Steps(props) {

    const { paso } = props;

  return (
    <div>
        
         {
            paso === "0" &&
           
   <ul className="stepper space-x-10 mt-10" data-mdb-stepper="stepper">
  <li className="stepper-step stepper-active">
    <div className="stepper-head ">
      <span className="stepper-head-icon"> 1 </span>
      <span className="stepper-head-text"> Ingresar Email </span>
    </div>
   
  </li>
  <li className="stepper-step">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 2 </span>
      <span className="stepper-head-text"> Validar Email </span>
    </div>
    
  </li>
  <li className="stepper-step">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 3 </span>
      <span className="stepper-head-text"> Confirmación </span>
    </div>
   
  </li>
</ul>
                
}

{
            paso === "1" &&
           
   <ul className="stepper space-x-10 mt-10" data-mdb-stepper="stepper">
  <li className="stepper-step ">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 1 </span>
      <span className="stepper-head-text"> Ingresar Email </span>
    </div>
   
  </li>
  <li className="stepper-step stepper-active">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 2 </span>
      <span className="stepper-head-text"> Validar Email </span>
    </div>
    
  </li>
  <li className="stepper-step">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 3 </span>
      <span className="stepper-head-text"> Confirmación </span>
    </div>
   
  </li>
</ul>
                
}
{
            paso === "2" &&
           
   <ul className="stepper space-x-10 mt-10" data-mdb-stepper="stepper">
  <li className="stepper-step ">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 1 </span>
      <span className="stepper-head-text"> Ingresar Email </span>
    </div>
   
  </li>
  <li className="stepper-step ">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 2 </span>
      <span className="stepper-head-text"> Validar Email </span>
    </div>
    
  </li>
  <li className="stepper-step stepper-active">
    <div className="stepper-head">
      <span className="stepper-head-icon"> 3 </span>
      <span className="stepper-head-text"> Confirmación </span>
    </div>
   
  </li>
</ul>
                
}
    </div>
  )
}
