import React from 'react'
import {useState} from 'react'
import {useSelector} from 'react-redux'


export default function Checkout() {
    
    const checkout = useSelector(state => state.checkout)

    const factura = useSelector(state => state.servicePayment)
 
      const [estado,setEstado] = useState(0)

     var intervalo = {};



async function checkStatus (idoperacion) { 
  return new Promise((resolve, reject) => {
      fetch('http://localhost:4001/api/servicePayment/transaccion?id=b1ad0981-3316-4e04-853a-b2e034ce7b4b')
      .then(response => response.json())
      .then(data => 
        {
          setEstado(data.estado)
          if (data.estado === 3) {
            clearInterval(intervalo)
            console.log('termino')
          }
          resolve(data)
          
        })
      .catch(error => reject(error));
  });
}

 intervalo = setInterval(() => {
  checkStatus()
}, 3000)



  return (
    <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
          <div className=' mb-10 sm:w-2/5 w-1/2 h-3/6 flex flex-col justify-evenly pl-5 bg-secondary rounded-md shadow-xl shadow-gray-300'>
        <h1 className='text-3xl '>La solicitud se realizo con éxito</h1>
        <p> Le estaremos enviando un mail a su casilla con los detalles de la operación </p>
        <p>Transaccion: {factura.IdServicePayment} </p>
{/*         <p>Realiza el seguimiento de la operación  </p> 
        <a href={`localhost:3000/tracking/${factura.IdServicePayment}`}>
        Acá
        </a> */}
        <p>
          Realiza el seguimiento de la operación <a className="text-blue-500"   href="http://localhost:3000/tracking/${factura.IdServicePayment}" rel="noreferrer">acá</a>
          </p>
        <p> Link de pago: <a className='text-blue-500'
        href={`https://checkouts.anclap.com/en/checkout/${checkout.checkoutId}`}
        >https://checkouts.anclap.com/en/checkout/{checkout.checkoutId} </a>
        </p>       
    </div>
</div>
  )
}
