import React from 'react'
import axios from 'axios'
import { useState, useEffect } from "react";
import { setProducto } from '../features/servicePayment/productoSlice';
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'


export default function SeleccionarProducto() {
    
  const [productos, setProductos] = useState([]);

  const [selectProducto, setSelectProducto] = useState(0);

  const dispatch = useDispatch();
   
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('/servicePayment/productos')
      .then(response => {
        const { data } = response;
        setProductos(data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
    
   const findProducto = (id) => {
        const producto = productos.find(producto => producto.idProducto === id);
        return producto;
    }
  
    const handleSubmit =async  (e) => {

        e.preventDefault();
      const product = await findProducto(selectProducto);
      dispatch(setProducto(product));
      navigate('/agregarParametros');
    }


  return (

    <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
     <div className='  sm:w-2/5 w-1/2 h-2/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
     <h1 className='text-3xl pl-12'>Pago Sin Factura:</h1>
             <div className='flex flex-col items-center space-y-8'>
             
      <select
      className=" block w-5/6 px-3 py-1.5 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
       value={selectProducto.value} onChange={(e)=> setSelectProducto(parseInt(e.target.value))}>
       <option value="0">Seleccione un producto</option>
        {productos.map(producto => (
          <option key={producto.idProducto} value={producto.idProducto}>{producto.descripcion}</option>
        ))}
      </select>

      <button 
      onClick= {handleSubmit}
      className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
      >Continuar</button>
      </div>  
    </div>
    </div>
  )
}