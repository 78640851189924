import { createSlice } from '@reduxjs/toolkit';

const initialState = {
/* idProducto: 0, */
arrayDatosAdicionales: [],
}

export const arrayDatosAdicionales = createSlice({
    name: 'arrayDatosAdicionales',
    initialState,
    reducers: {
        setArrayDatosAdicionales: (state, action) => {
            state.arrayDatosAdicionales = action.payload.arrayDatosAdicionales;

        }
    },
    returns: {
        setSinFactura: (idProducto, datosAdicionales) => ({
            type: 'arrayDatosAdicionales/setArrayDatosAdicionales',
            payload: {
              arrayDatosAdicionales,
            },
        }),
    },
});


export var { setArrayDatosAdicionales } = arrayDatosAdicionales.actions;

export const selectDatosAdicionales = (state) => state.arrayDatosAdicionales.arrayDatosAdicionales;

export default arrayDatosAdicionales.reducer;