import React from 'react'
import {Route, Routes } from 'react-router-dom'

import Home from './pages/Home';
import ConFactura from './pages/ConFactura';
import SeleccionarProducto from './pages/SinFactura';
import Confirmacion from './pages/Confirmacion';
import DatosAdicionales from './pages/DatosAdicionales';
import Checkout from './pages/Checkout';
import AgregarParametros from './pages/AgregarParametros';
import ImporteVariable from './pages/ImporteVariable';
import Nav from './components/Nav';
import Register from './pages/Register';
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import Tracking from './pages/Tracking';
import PagosAnteriores from './pages/PagosAnteriores';
import Comprobante from './components/Comprobante';

export default function App() {
  return (
    
   <React.Fragment>
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={
        <React.Fragment>
        <Nav/>
      <Home />
      </React.Fragment>
      } />
      <Route path="/register" element={
        <React.Fragment>
        <Nav/>
      <Register />
      </React.Fragment>
      } />
      <Route path="/login" element={
        <React.Fragment>
        <Nav/>
      <Login />
      </React.Fragment>
      } />
      

      <Route path="/conFactura" element={
        <React.Fragment>  
        <Nav/>
      <ConFactura />
      </React.Fragment>
      } />
      <Route path="/sinFactura" element={
        <React.Fragment>
        <Nav/>
      <SeleccionarProducto />
      </React.Fragment>
      } />
     
       <Route path='/datosAdicionales' element={
        <React.Fragment>
        <Nav/>
       <DatosAdicionales />
        </React.Fragment>
       } />
      <Route path='/agregarParametros' element={
        <React.Fragment>
        <Nav/>
      <AgregarParametros />
      </React.Fragment>
      } />
      <Route path='/importeVariable'
       element={
        <React.Fragment>
        <Nav/>
       <ImporteVariable />
        </React.Fragment>
       } />
      <Route path="/confirmacion" element={
        <React.Fragment>
        <Nav/>
      <Confirmacion />
      </React.Fragment>
      } />
      <Route path="/checkout" element={
        <React.Fragment>
        <Nav/>
      <Checkout />
      </React.Fragment>
      } />
      
      <Route path="/tracking/:id" element={
        <React.Fragment>
        <Nav/>
      <Tracking />
      </React.Fragment>
      } />
      
      <Route path='/pagosAnteriores' element={
        <React.Fragment>
          <Nav/>
          <PagosAnteriores />
        </React.Fragment>
      }
      />
       
      <Route path='/comprobante/:id' element={
        <React.Fragment>
          <Comprobante />
        </React.Fragment>
      }
      />


    </Routes>

    </React.Fragment>

  )
}