import React from 'react'
import { useEffect,useState } from 'react';
import axios from 'axios';
import { NavLink, useParams } from 'react-router-dom';

export default function Tracking() {
    
    const {id} = useParams();
    
    const [transaccion,setTransaccion] = useState({
        idServicePayment:'',
        descripcion:'',
        moneda:'',
        importe:0,
        estado:0,
        createdAt:'',
  
    });
    
    async function fetchServicePayment() {
        const response = await axios.get(`/servicePayment/transaccion?id=${id}`);
        setTransaccion(
            {
                idServicePayment:response.data.IdServicePayment,
                descripcion:response.data.responsecreation.descripcion,
                moneda:response.data.responsecreation.moneda,
                importe:response.data.responsecreation.importe,
                estado:response.data.estado,
                createdAt:response.data.createdAt,
            }
        );
    }

    

    useEffect(() => {
        fetchServicePayment();
     }
    , []);

  return (
   
         <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
          <div className='mb-11 sm:w-2/5 w-1/2 h-3/6 flex flex-col justify-evenly items-center bg-quaternary rounded-md shadow-xl shadow-gray-300'>
         {/*     <div className='flex flex-col items-center space-y-8'> */}
            <h1 className='text-3xl '>Detalles de la operación:</h1>
            <p className='text-xl '> PRODUCTO:    {transaccion.descripcion} </p>
            <p className='text-xl'> IMPORTE:     {transaccion.moneda} {transaccion.importe} </p>
            <div className='flex flex-row items-center'>
           {
            transaccion.estado === 1 &&
            <>
            <p className='text-xl'> ESTADO: Pendiente </p>
            <button className='mx-2 my-2 bg-quaternary transition duration-150 ease-in-out focus:outline-none rounded-md text-white underline underline-offset-1 px-6 py-2 text-sm'
            onClick={fetchServicePayment}>
                Actualizar 
            </button>
            </>
           }
            { 
            transaccion.estado === 2 &&
            <>
            <p className='text-xl'> ESTADO: Pagado </p>
        
                <NavLink to={`/comprobante/${id}`} className='mx-2 my-2 bg-quaternary transition duration-150 ease-in-out focus:outline-none rounded-md text-white underline underline-offset-1 px-6 py-2 text-sm'>
                Ver Comprobante
                </NavLink>
            
            </>
             }
            {
                transaccion.estado === 3 &&
                <>
                <p className='text-xl'> ESTADO: Pagado pendiente de acreditación </p>
                <button className='mx-2 my-2 bg-quaternary transition duration-150 ease-in-out focus:outline-none rounded-md text-white underline underline-offset-1 px-6 py-2 text-sm'
            onClick={fetchServicePayment}>
                Actualizar 
            </button>
                </>
            }


            </div>
            
        </div>
    </div>
    

  )
}
