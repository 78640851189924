import axios from 'axios';

export function fetchCheckout(idServicePayment) {
  return new Promise((resolve, reject) => {
    axios.post('/checkout/create', {
        IdServicePayment: idServicePayment
    })
      .then(response => {
        const { data } = response;
        
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
}