import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    codigoBarras:0,
}
 
export const codigoBarras = createSlice({
    name: 'codigoBarras',
    initialState,
    reducers: {
        setCodigoBarras: (state, action) => {
            state.codigoBarras = action.payload
        }
    },
    returns: {
        setCodigoBarras: (codigoBarras) => ({
            type: 'codigoBarras/setCodigoBarras',
            payload: {
                codigoBarras,
            },
        }),
    },
    

});


export const { setCodigoBarras } = codigoBarras.actions;


export default codigoBarras.reducer;