import { createSlice } from '@reduxjs/toolkit';


const initialState =  {
  descripcion: '',
  importe: 0,
  moneda: '',
  IdServicePayment:'',
  idTransaccion: 0,
 
  
}


export const servicePayment = createSlice({
  name: 'servicePayment',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setServicePayment: (state, action) => {
      state.descripcion = action.payload.descripcion;
      state.importe = action.payload.importe;
      state.moneda = action.payload.moneda;
      state.IdServicePayment = action.payload.IdServicePayment;
      state.idTransaccion = action.payload.idTransaccion;
  

    },
  },
  returns: {
    setServicePayment: (descripcion, importe, moneda, IdServicePayment, idTransaccion) => ({
      type: 'servicePayment/setServicePayment',
      payload: {
        descripcion,
        importe,
        moneda,
        IdServicePayment,
        idTransaccion,
      },
    }),
  },
  

});

export const { setServicePayment } = servicePayment.actions;

/* export const { increment, decrement, incrementByAmount } = counterSlice.actions; */

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


export default servicePayment.reducer;
