import React from 'react'
import {useState, useEffect} from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom'

export default function PagosAnteriores() {
    
   var [pagos, setPagos] = useState([])

    useEffect(() => {
        axios.get('/servicePayment/getPayments')
          .then(response => {
            const { data } = response;
            setPagos(data);
          })
          .catch(error => {
            console.log(error);
          });
    }, []);
  
     

  return (
   
    
    <div className='font-sans w-full h-screen flex flex-col items-center text-principal mt-12 '>
   
    <div className='mb-10' >
    <h1 className='text-2xl'>PAGOS ANTERIORES</h1>
    </div>

    <div className='flex flex-col'>

    <div className='grid grid-cols-3 gap-x-28 gap-y-4'>
      <p className='text-xl'>FECHA</p>
      <p className='text-xl'>EMPRESA</p>
      <p className='text-xl'>COMPROBANTE</p>
    {pagos.map(pago => (
         <>
        <p className='text-sm'>{((pago.updatedAt).slice(0,10)).split("-").reverse().join("-")}</p>
        <p className='text-sm'>{(pago.responsecreation.descripcion).charAt(0).toUpperCase() + (pago.responsecreation.descripcion).slice(1)}</p>
        <NavLink to={`/comprobante/${pago.IdServicePayment}`} >
        <p className='text-sm'>Comprobante</p>
        </NavLink>
        </>
    ))}
 
     </div>
     
    </div>
    </div>
    
    
  
        
    
  )
}
