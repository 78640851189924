
export const comprobantes =

    {
        "id": 3771918,
        "idTransaccion": "5695515099726",
        "monto": 2282.18,
        "comisionWU": 0,
        "comisionPP": 0,
        "moneda": "ARS",
        "descripcion": "2DP EDESAL",
        "estado": "CONFIRMADA",
        "fecha": "2022-03-21T17:58:35",
        "conciliada": true,
        "nombreAgente": "Grupo Anchor SA",
        "codigoBarras": "50110469001140222002282187",
        "comprobante": "\nCOMPROBANTE DE PAGO DE\n2DP EDESAL\n     * SEPSA - PAGO FACIL *\n0013 L28022 21/03/2022 17:58:44\n0110469001          E $$$$$$$$$2282,18\nED432C00 2DP 2DP EDESAL                 \nCONSULTE EL PAGO FACIL MAS CERCANO\nLLAMANDO AL : 0800-444-3224  \nO INGRESANDO A :   \nwww.pagofacil.com.ar\n\nESTE COMPROBANTE ES VALIDO SI TODOS\nLOS DATOS SE CORRESPONDEN CON LOS DE\nLA FACTURA.\n\n\n50110469001140222002282187\n"
    }
    