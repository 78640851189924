import React from 'react'
import { useState } from 'react'
import axios from 'axios';
import exito from '../assets/exito.gif'
import {useNavigate} from 'react-router-dom'
import {setLogin} from '../features/user/userSlice'
import { useSelector,useDispatch } from 'react-redux';


export default function Login() {
     
    const navigate = useNavigate()

    const dispatch = useDispatch();

    var usuario = useSelector(state => state.user)

    const [step, setStep] = useState(0);
    
    const [email, setEmail] = useState('');

    const [token, setToken] = useState('');
    
    const [error, setError] = useState('');

    const [respuesta, setRespuesta] = useState('');
    
    const [showError, setShowError] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);

    function handleSubmit(e) {
        e.preventDefault();
        axios.post ('/users/getOtp', {
            email: email
        })
        .then(response => {
            if (response.status === 200) {
                setStep(1)
                setError(false)
            }
          } )

          .catch(error => {
            console.log(error)
            setError(error.response.data.message)
            setShowError(true)
        }
        )
    }

    function handleSubmitValidate(e) {
        e.preventDefault();
        axios.post ('/users/otp', {
            email: email,
            otp: token
        })
        .then(response => {
            if (response.status === 200) {
                dispatch(setLogin({
                    idUser: response.data.idUser,
                    token: response.data.token
                }))

                setStep(2)
                setError(false)
            }
            } )

          .catch(error => {
            console.log(error)
            setError(error.response.data.message)
            setShowError(true)
        }
        )
    }

    function handleSubmitResend(e) {
        e.preventDefault();
        axios.post ('/users/getOtp', {
            email: email
        })
        .then(response => {
            if (response.status === 200) {
                setStep(1)
                setError(false)
                setRespuesta(response.data.message)
                setShowSuccess(true)
            }
          } )

          .catch(error => {
            console.log(error)
            setError(error.response.data.message)
            setShowError(true)
        }
        )
    }
     
    const handleSuccess = (e) => {
        e.preventDefault();
        navigate('/home')
    }

  return (
    <>
        {
            step === 0 &&
            <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
          <div className=' mb-10 sm:w-2/5 w-1/2 h-2/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
      <h1 className='text-3xl pl-12'>Login:</h1>
      <form>
       <div className='flex flex-col items-center space-y-8'>
       <p>Ingresa tu mail y te enviaremos una otp</p>
      <input  className="form-control block w-1/2 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput125" placeholder="Email" type="email" 
      value={email}
     onChange={e => setEmail(e.target.value)}  

     />
      <button 
      type='submit'
      className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
      onClick={handleSubmit}
      >
      Continuar
      </button>
     {
         showError &&
            <div className='text-red-500'>
           <p> {error} </p>
             </div>
     }
     </div>
    </form>
   </div>
</div>
        }
        {
            step === 1 &&
     <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
          <div className=' mb-10 sm:w-2/5 w-1/2 h-2/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
        <h1 className='text-3xl pl-9'>Validar Otp:</h1>
        <div className='flex flex-col items-center justify-center space-y-8'>
        <div className='text-center'>
        <p>Ingresa el código recibido:</p>
        </div>
        
        <input className="form-control block  w-1/2  px-3 py-1.5 text-base font-normal text-gray-700  bg-white bg-clip-padding border border-solid border-gray-300  rounded  transition ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none "
        type="text"
        placeholder="Código de validación"
        value={token}
        onChange={e => setToken(e.target.value)}
        />
        <div className='flex flex-row'>
        <button
        type='submit'
        className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
        onClick={handleSubmitValidate}
        >
        Validar
        </button>
        
        <button
        type='submit'
        className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
        onClick={handleSubmitResend}
        >
        Reenviar código
        </button>
       
       </div>
       {
         showError &&
            <div className='text-red-500'>
           <p> {error} </p>
             </div>

     }
     {
          showSuccess &&
            <div className='text-green-500'>
            <p> {respuesta} </p>
              </div>
     }
      </div>
     </div>
    </div>
        }
        {
            step === 2 &&
            <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
          <div className=' mb-10 sm:w-2/5 w-1/2 h-3/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
        
         <div className='flex flex-col items-center justify-center space-y-8'>
         <h1 className='text-3xl pl-9'>Bienvenido</h1>
           <div className='w-24 h-24'>
             <img src={exito} alt="exito" className='w-auto h-full'/>
           </div>
        <div className='text-center'>
         <p>Ya podes operar y consultar tus operaciones anteriores</p>
        </div>
         <button
        type='submit'
        className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
        onClick={handleSuccess}
         >
        Continuar
         </button>
         </div>
     </div>
   </div>
        }
        </>
  )
}
