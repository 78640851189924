
import React from 'react'
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';

export default function Home() {

    const usuario = useSelector(state => state.user);
        return (

            <div className='font-sans w-auto h-screen flex justify-center items-center  text-principal'>
         
         
                <div className='mb-10 sm:w-2/5 w-1/2 h-2/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
            {   
                usuario.idUser > 0 &&
                <>
                 <NavLink className='flex justify-end mr-20 text-azulAnclap' to='/pagosAnteriores'>
                <p>Pagos Anteriores</p>
                </NavLink>
                </>
         }
            
                <h1 className='text-3xl ml-20'>Seleccioná la forma de pago:</h1>
                
                <div  className='flex flex-row space-x-20 justify-center '>
                <NavLink to='/conFactura' >   
                <button className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white border border-gray-300 px-8 py-3 text-sm">
                CON FACTURA
                </button>
                </NavLink>
                
                <NavLink to='/sinFactura' >
                <button className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white border border-gray-300 px-8 py-3 text-sm">
                SIN FACTURA
                </button>   
                 </NavLink>   
                </div>
                </div>
            
            </div>
            
        )
      }

