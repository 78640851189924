import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { createCheckout } from '../features/checkout/checkoutSlice';
import {useNavigate} from 'react-router-dom'


export default function Confirmacion() {

    const factura = useSelector(state => state.servicePayment)
     
    const IdServicePayment = factura.IdServicePayment;
    
    const dispatch = useDispatch();

  
   
    const navigate = useNavigate();
    //router.post('/checkout/create', checkoutController.checkoutApi)

    const redirectTimeOut = () => {
        setTimeout(() => {
            navigate('/checkout')
        }, 3000);
    }


    const handleSubmit = (e) => {
        
        e.preventDefault();
       dispatch(createCheckout(IdServicePayment));
       redirectTimeOut()
        
    }
    
   


  return (
    <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
          <div className='mb-10 sm:w-2/5 w-1/2 h-3/6 flex flex-col justify-evenly items-center  bg-secondary rounded-md shadow-xl shadow-gray-300'>
         {/*     <div className='flex flex-col items-center space-y-8'> */}
            <h1 className='text-3xl '>Confirmación:</h1>
            <p className='text-xl'> PRODUCTO:    {factura.descripcion} </p>
            <p className='text-xl'> IMPORTE:     {factura.moneda} {factura.importe} </p>
            <button  
           className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-md"
            onClick={handleSubmit}
            >
            Pagar
            </button>
          
        </div>
    </div>
  )
}


