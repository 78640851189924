import React from 'react'
import { comprobantes } from "../comprobante";
import anclapLogo from '../assets/anclap.jpeg'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router-dom';
import { useEffect,useState } from 'react';
import axios from 'axios';

export default function Comprobante() {

    const {id} = useParams();

    const [transaccion,setTransaccion] = useState({
      idServicePayment:'',
      descripcion:'',
      moneda:'',
      importe:0,
      updatedAt:'',
      transaccionId:'',
      codigoBarras:'',
      comprobante:'',
  });
  
  async function fetchServicePayment() {
      const response = await axios.get(`/servicePayment/transaccion?id=${id}`);
      setTransaccion(
          {
              idServicePayment:response.data.IdServicePayment,
              descripcion:response.data.responsecreation.descripcion,
              moneda:response.data.responsecreation.moneda,
              importe:response.data.responsecreation.importe,
              updatedAt:response.data.updatedAt,
              transaccionId: response.data.responsecreation.idTransaccion,
              codigoBarras: response.data.pasosnecesarios.codigoBarras,
              comprobante: response.data.resultad.comprobante,
             
            }
      );
  }

  

  useEffect(() => {
      fetchServicePayment();
   }
  , []);

    var fecha = (transaccion.updatedAt).slice(0,10)

 var _exportPdf = () => {

     html2canvas(document.querySelector("#capture")).then(canvas => {
        document.body.appendChild(canvas);  // if you want see your screenshot in body.
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'a4', false);
        pdf.addImage(imgData, 'PNG', 0, 0, 600, 0, undefined, false)
        pdf.save("download.pdf"); 
    });

 }
  return (

     <div id="capture" className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
       
          <div className=' mb-10 sm:w-2/5 w-2/3 h-5/6 flex flex-col justify-evenly items-center  bg-secondary rounded-md shadow-xl shadow-gray-300'>
       
         <div className='flex flex-row justify-evenly gap-x-4 mt-4'>
          <img src={anclapLogo} alt='anclap' className='h-10 w-10 rounded-md'/>
         <div>
          <p>Comprobante de pago</p>
          <p>#{transaccion.idServicePayment}</p>
         </div>  
        </div>
       
        <div className='flex flex-col justify-evenly items-center gap-y-4'>
        
         <div >
          <p className='text-sm text-gray-400'>pagaste a:</p>
          <h1 className='text-xl'>{transaccion.descripcion}</h1>
         </div>
        
          <div>
        <p className='text-sm text-gray-400'>un monto de:</p>
        <h1 className='text-xl text-quaternary'>${transaccion.importe}</h1>
         </div>
       
        </div>
      
        <div className='w-2/3'>
          <p  className='text-sm text-gray-400'>Fecha de pago</p>
          <p>{ fecha.split("-").reverse().join("-")}</p>
          <hr className='solid'/>
        </div>
        
        <div className='w-2/3'>
        {
          transaccion.codigoBarras ? (
            <>
            <p className='text-sm text-gray-400'>Código de Barras</p>
             <p>{transaccion.codigoBarras}</p>
            <hr className='solid'/>
            </>
            ) : (
               <></>
            )
        }

        </div>

        <div className='w-2/3'>
            <p className='text-sm text-gray-400'>Número de transacción</p>
            <p>{transaccion.transaccionId}</p>
            <hr className='solid'/>
        </div>
         
         <div className='w-4/5 text-xs text-gray-400 mt-4'>
            <texterea>
            Si bien se respetará la fecha de pago emitida en este comprobante, la empresa puede demorar hasta 48 horas hábiles en verlo reflejado. Si necesitás contactarte con Pago Fácil para realizar alguna consulta sobre tu pago, llamá al 0800-444-3224 o ingresá en www.pagofacil.com.ar.
            </texterea>
         </div>
           
        <div className='w-4/5 text-xs text-gray-400'>
            <texterea>{transaccion.comprobante}</texterea>
        </div>
          <div>
            <button
            onClick={_exportPdf}
            >Descargar</button>
      
          </div>
          

       <div>
       </div> 
    </div>
    </div>
  )
}
