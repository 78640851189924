import React from 'react'
import { useState } from "react";
import axios from 'axios';
import {  useDispatch } from 'react-redux'
import { setServicePayment } from '../features/servicePayment/servicePaymentSlice';
import {useNavigate} from 'react-router-dom'
import { setCodigoBarras } from '../features/servicePayment/codigoBarras';
import { setDescripcion} from '../features/servicePayment/descripcionSlice';

export default function ConFactura() {

  
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [codigoDeBarras, setDeCodigoBarras] = useState(0);
   

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/servicePayment/facturas', {
            codigoBarras: codigoDeBarras
        })
            .then(response => {
               
         console.log(response.data)
     
                if (response.data.idTransaccion != null) {
                    dispatch(setServicePayment(response.data));
                    navigate('/confirmacion')
                }
               else if (response.data.importeVariable) {
                  dispatch(setCodigoBarras(codigoDeBarras))
                  dispatch (setDescripcion(response.data.descripcion))
                    navigate('/importeVariable')
                }
                else if (!response.data.importeVariable) {
                    dispatch(setCodigoBarras(codigoDeBarras))
                  dispatch (setDescripcion(response.data.descripcion))
                    navigate('/datosAdicionales')
                }
            })
          
    }
 


    return (
      
        <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
          <div className='mb-10  sm:w-2/5 w-1/2 h-2/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
            <h1 className='text-3xl pl-12'>Pago Con Factura:</h1>
             <div className='flex flex-col items-center space-y-8'>
             
            <input
            // className='p-2 w-3/4 text-center text-sm rounded-sm bg-secondary ' 
            className=" block w-5/6 px-3 py-1.5 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
             type="number" 
             placeholder="ingrese código de barras"
             onChange={e => setDeCodigoBarras(e.target.value)}
              />
            <button 
            className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
            onClick={handleSubmit}
            >
            Continuar
            </button>
            
            </div>
            </div>
           </div>

        
    )
}

      