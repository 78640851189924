import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import { useState } from 'react'
import { setArrayDatosAdicionales } from '../features/servicePayment/arrayDatosAdicionalesSlice'
import axios from 'axios'
import { setServicePayment } from '../features/servicePayment/servicePaymentSlice'
import {useNavigate} from 'react-router-dom'
import { setDescripcion} from '../features/servicePayment/descripcionSlice'
import {selectDatosAdicionales} from '../features/servicePayment/arrayDatosAdicionalesSlice'

export default function ImporteVariable() {
    
   
  const dispatch = useDispatch()

  const navigate = useNavigate()

  var datosAdicionales = useSelector(selectDatosAdicionales)

   const estado = useSelector(state => state.descripcion)

  const producto = useSelector(state => state.producto)

    const codigoDeBarras = useSelector(state => state.codigoBarras)

  var ordinal = datosAdicionales.length;

  var array=[]

  const [valor, setValor] = useState('');

  var agregarDatosAdicionales = async (e) => {
    ordinal++;
      return array = [...datosAdicionales, {
        ordinal: ordinal,
        valor: valor  
      }];
      
    }

    async function handleSubmitConFactura(e) {
        e.preventDefault();
        console.log (codigoDeBarras.codigoBarras)
        await agregarDatosAdicionales();
        console.log(array)
        dispatch(setArrayDatosAdicionales({
            arrayDatosAdicionales: array
            }))

            await axios.post('/servicePayment/facturas', {
                codigoBarras: codigoDeBarras.codigoBarras,
                datosAdicionales: array
            })
            .then(response => {
                console.log(response.data)
                if (response.data.idTransaccion != null) {
                    dispatch(setServicePayment(response.data));
                    navigate('/confirmacion')
                }
                else if (response.data.importeVariable) {
                    dispatch (setDescripcion(response.data.descripcion))
                    setValor('')
                    navigate('/importeVariable')
                }
                else if (!response.data.importeVariable) {
                    dispatch (setDescripcion(response.data.descripcion))
                    setValor('')
                    navigate('/datosAdicionales')
                }
            })
        }

    
    async function handleSubmit(e) {
        e.preventDefault();
        await agregarDatosAdicionales();
        console.log(array)
        dispatch(setArrayDatosAdicionales({
            arrayDatosAdicionales: array
        }))

       await axios.post('/servicePayment/productos', {
    
       idProducto: producto.idProducto,
       datosAdicionales: array

    })
    .then(response => {
      dispatch (setDescripcion(response.data.descripcion))
      console.log(response.data)

      if (response.data.idTransaccion != null) {
          dispatch(setServicePayment(response.data));
          navigate('/confirmacion')
      }
      else if (response.data.importeVariable) {
        dispatch (setDescripcion(response.data.descripcion))
         setValor('')
          navigate('/importeVariable')
      }
      else if (!response.data.importeVariable) {
        dispatch (setDescripcion(response.data.descripcion))
          setValor('')
          navigate('/datosAdicionales')
      }
  })



  }


  
  return (
    <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
    <div className='mb-10 sm:w-2/5 w-1/2 h-2/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
      <h1 className='text-3xl pl-12'>Ingresa Monto a Pagar:</h1>
       <div className='flex flex-col items-center space-y-8'>
           
             <input
                className=" block w-5/6 px-3 py-1.5 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                type="number"
                placeholder={estado.descripcion}
                value={valor}
                onChange={e => setValor (parseInt(e.target.value))}
            />
            {
                codigoDeBarras.codigoBarras !== 0 ?
                <button
                   className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
                    onClick={handleSubmitConFactura}
                >
                    Agregar 
                </button>
                :
                <button
                   className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
                    onClick={handleSubmit}
                >
                    Agregar
                </button>

            }
          </div>
        </div>
    </div>
  )
}
