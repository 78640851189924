import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import Steps from '../components/Steps'
import exito from '../assets/exito.gif'
import { useNavigate } from 'react-router-dom'

export default function Register() {

    const navigate = useNavigate()

   const [email, setEmail] = useState('')

   const [error, setError] = useState('')

   const [respuesta, setRespuesta] = useState('')

   const [token, setToken] = useState('')

   const [step, setStep] = useState(0)

   const [showError, setShowError] = useState(false)

   const [showSuccess, setShowSuccess] = useState(false)

   function handleSubmit(e) {
      e.preventDefault()
      axios.post ('/users/create', {
            email: email
        })
        .then(response => {
            console.log(response.data.message)
            if (response.status === 200) {
                setStep(1)
                setError(false)
            }
         } )
        
         .catch(error => {
            console.log(error)
            setError(error.response.data.message)
            setShowError(true)
            setEmail('')
        })
    }
       

    
    function handleSubmitValidate(e) {
      e.preventDefault()
      axios.post ('/users/validate', {
            email: email,
            token: token
        })
        .then(response => {
            console.log(response.data.message)
            if (response.status === 200) {
                setStep(2)
                setError(false)
            }
          } )

          .catch(error => {
            console.log(error)
            setError(error.response.data.message)
            setShowError(true)
        }
        )
    }

    function handleResendToken(e) {
      e.preventDefault()
      axios.post ('/users/resendtoken', {
            email: email
        })
        .then(response => {
            console.log(response.data.message)
            if (response.status === 200) {
                setRespuesta(response.data.message)
                setError(false)
                setShowSuccess(true)
            }

          } )

          .catch(error => {
            console.log(error)
            setError(error.response.data.message)
            setShowError(true)
        }
        )
    } 

    const handleSuccess = (e) => {
        e.preventDefault()
        navigate('/login')
    }



  return (
    <>
    {
      step === 0 &&
   <div className='font-sans w-auto h-screen flex flex-col justify-start items-center text-principal'>
     <Steps paso="0" />
    <div className=' mb-10 sm:w-2/5 w-1/2 h-3/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300 mt-10'>
      <h1 className='text-3xl pl-12'>Registro:</h1>
    
      <form>
       <div className='flex flex-col items-center space-y-8'>
       <p>Ingresa tu email para poder acceder al historial de tus operaciones</p>
      <input  className="form-control block w-1/2 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out  m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" id="exampleInput125" placeholder="Email" type="email" value={email} onChange={e => setEmail(e.target.value)}  />
      <button 
      type='submit'
      className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
      onClick={handleSubmit}
      >
      Continuar
      </button>
     {
         showError &&
            <div className='text-red-500'>
           <p> {error} </p>
             </div>

     }
     </div>
    </form>
   </div>
</div>

   }
    {
      step === 1 &&
    <div className='font-sans w-auto h-screen flex flex-col justify-start items-center text-principal'>
      <Steps paso="1" />
      <div className=' mb-10 sm:w-2/5 w-1/2 h-3/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300 mt-10'>
        <h1 className='text-3xl pl-9'>Validar Email:</h1>
        <div className='flex flex-col items-center justify-center space-y-8'>
        <div className='text-center'>
        <p>Gracias por registrarte, te enviamos un mail a tu casilla con el código</p>
        </div>
        
        <input className="form-control block  w-1/2  px-3 py-1.5 text-base font-normal text-gray-700  bg-white bg-clip-padding border border-solid border-gray-300  rounded  transition ease-in-out  m-0  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none "
        type="text"
        placeholder="Código de validación"
        value={token}
        onChange={e => setToken(e.target.value)}
        />
        <div className='flex flex-row'>
        <button
        type='submit'
        className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
        onClick={handleSubmitValidate}
        >
        Validar
        </button>
        
        <button
        type='submit'
        className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
        onClick={handleResendToken}
        >
        Reenviar código
        </button>
       
       </div>
       {
         showError &&
            <div className='text-red-500'>
           <p> {error} </p>
             </div>

     }
     {
          showSuccess &&
            <div className='text-green-500'>
            <p> {respuesta} </p>
              </div>
     }
      </div>
     </div>
    </div>
    }
    {
      step === 2 &&
    <div className='font-sans w-auto h-screen flex flex-col justify-start items-center text-principal'>
      <Steps paso="2" />
        <div className=' mb-10 sm:w-2/5 w-1/2 h-3/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300 mt-10'>
         
         <div className='flex flex-col items-center justify-center space-y-6'>
          <h1 className='text-3xl '>Confirmación</h1>
             <div className='w-24 h-24'>
             <img src={exito} alt="exito" className='w-auto h-full'/>
             </div>
           <div className='text-center'>
           <p>Gracias por registrarte, ahora puedes acceder al historial de tus operaciones</p>
        </div>

        <button
        type='submit'
        className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
        onClick={handleSuccess}
        >
        Loggin
        </button>

       </div>
      </div>
    </div>

    }

    </>
  )
}

