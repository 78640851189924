import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCheckout } from './checkoutApi';

const initialState = {
    checkoutId: '',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const createCheckout = createAsyncThunk(
  'checkout/fetchCheckout',
  async (idServicePayment) => {
    const response = await fetchCheckout(idServicePayment);
    console.log(response);
    return response;
  }
);

export const checkout = createSlice({
  name: 'checkout',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setCheckoutId: (state, action) => {
        state.checkoutId = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {
    [createCheckout.fulfilled]: (state, action) => {
        state.checkoutId = action.payload.checkoutId;
    }
    },

});

export const { setCheckout } = checkout.actions;

/* export const { increment, decrement, incrementByAmount } = counterSlice.actions; */

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`


export default checkout.reducer;