import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setArrayDatosAdicionales } from '../features/servicePayment/arrayDatosAdicionalesSlice'
import { setDescripcion } from '../features/servicePayment/descripcionSlice';
import { setServicePayment } from '../features/servicePayment/servicePaymentSlice';
import {useNavigate} from 'react-router-dom'
import axios from 'axios';

export default function AgregarParametros() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const producto = useSelector(state => state.producto)
   
    const parametros = producto.parametros
      
    var arrayParametros = [];

    var [datosAdicionales , setDatosAdicionales] = useState({
        ordinal: 0,
        valor: ''
    })
    
    async function agregarParametros(){
        arrayParametros.push(datosAdicionales);

    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        await agregarParametros();
       dispatch(setArrayDatosAdicionales({
            /* idProducto: producto.idProducto, */
            arrayDatosAdicionales: arrayParametros
       }))
         axios.post('/servicePayment/productos', {
            idProducto: producto.idProducto,
            datosAdicionales: arrayParametros
            

        })
            .then(response => {
                console.log(response.data)

                if (response.data.idTransaccion != null) {
                    dispatch(setServicePayment(response.data));
                    navigate('/confirmacion')
                }
                else if (response.data.importeVariable) {
                    dispatch (setDescripcion(response.data.descripcion))
                    navigate('/importeVariable')
                }
                else if (!response.data.importeVariable) {

                    dispatch (setDescripcion(response.data.descripcion))
                    navigate('/datosAdicionales')
                }
            })
    }


  return (
    <div className='font-sans w-auto h-screen flex justify-center items-center text-principal'>
    <div className='  sm:w-2/5 w-1/2 h-2/6 flex flex-col justify-evenly  bg-secondary rounded-md shadow-xl shadow-gray-300'>
      <h1 className='text-3xl pl-12'>Elija Un Parámetro de Búsqueda:</h1>
       <div className='flex flex-col items-center space-y-8'>
     <select
       className=" block w-5/6 px-3 py-1.5 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
       value= {datosAdicionales.ordinal}
       onChange={e => setDatosAdicionales({...datosAdicionales, ordinal: parseInt(e.target.value)})}
        >
        <option value="">Seleccione una opción</option>
        {parametros.map(parametro => (
          <option key={parametro.ordinal} value={parametro.ordinal}>
            {parametro.descripcion}
          </option>
        ))}
        </select>
       
        <input
           className=" block w-5/6 px-3 py-1.5 text-base text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
           type="text"
          valor={datosAdicionales.valor}
            onChange={e => setDatosAdicionales({...datosAdicionales, valor: e.target.value})}
            />

                   <button 
            className="mx-2 my-2 bg-principal transition duration-150 ease-in-out focus:outline-none rounded-md text-white  px-6 py-2 text-sm"
            onClick={handleSubmit}
            >
            Continuar
            </button>
            </div>
      </div>
    </div>
  )
}
