import { configureStore } from '@reduxjs/toolkit';

import servicePaymentReducer from '../features/servicePayment/servicePaymentSlice';
import checkoutReducer from '../features/checkout/checkoutSlice';
import productoReducer from '../features/servicePayment/productoSlice';
import arrayDatosAdicionalesReducer from '../features/servicePayment/arrayDatosAdicionalesSlice';
import descripcionReducer from '../features/servicePayment/descripcionSlice';
import codigoBarrasReducer from '../features/servicePayment/codigoBarras';
import userReducer from '../features/user/userSlice';

export var store = configureStore({
  reducer: {
    servicePayment: servicePaymentReducer,
    checkout: checkoutReducer,
    producto: productoReducer,
    arrayDatosAdicionales: arrayDatosAdicionalesReducer,
    descripcion: descripcionReducer,
    codigoBarras: codigoBarrasReducer,
    user: userReducer,
  },
});

