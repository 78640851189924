import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    descripcion: '',
}
 
export const descripcion = createSlice({
    name: 'descripcion',
    initialState,
    reducers: {
        setDescripcion: (state, action) => {
            state.descripcion = action.payload
        }
    },
    returns: {
        setDescripcion: (descripcion) => ({
            type: 'descripcion/setDescripcion',
            payload: {
                descripcion,
            },
        }),
    },
    

});


export const { setDescripcion } = descripcion.actions;


export default descripcion.reducer;