import React from 'react'
import anclapLogo from '../assets/anclap.jpeg'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import {setLogout} from '../features/user/userSlice'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function Nav() {
     
    const usuario = useSelector(state => state.user);

    const dispatch = useDispatch(); 

    const navigate = useNavigate();

    function logout() {
        console.log('logout');
     //eliminar token del localstorage
        localStorage.removeItem('token');
        //eliminar token del state
        dispatch(setLogout());
        //redireccionar a login
        navigate('/');
    }

  return (
    <>
        <div className='shadow-md shadow-gray-300 font-sans text-principal bg-secondary h-20 flex justify-center items-center'>
           
                <div className='flex flex-row'>
                    <img src={anclapLogo} alt='anclap' className='h-10 w-10 rounded-md'/>
                    <h1 className='text-3xl ml-2'>PAGOS EN LINEA</h1>
                
                </div>               
                {
                usuario.idUser > 0 && 
                <div className='relative left-80 text-md'>
                <button
                onClick={logout}
                >Log Out</button>
                </div>
                
              }
        </div>


    </>
    
    )
 
}
