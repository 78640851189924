import { createSlice } from '@reduxjs/toolkit';

const initialState = {
descripcion: '',
idProducto: 0,
parametros: [],
rubro: '',
}

export const producto = createSlice({
    name: 'producto',
    initialState,
    reducers: {
        setProducto: (state, action) => {
            state.descripcion = action.payload.descripcion;
            state.idProducto = action.payload.idProducto;
            state.parametros = action.payload.parametros;
            state.rubro = action.payload.rubro;
        }
    },
    returns: {
        setProducto: (idProducto, parametros) => ({
            type: 'producto/setProducto',
            payload: {
                idProducto,
                parametros,
            },
        }),
    },
    
});


export const { setProducto } = producto.actions;

export default producto.reducer;



